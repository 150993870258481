export const getBusinessAuthQueryGql = `query GetBusinessAuth ($input: GetBusinessInput!) {
  getBusiness (input: $input) {
    id
    cognito {
      identityPoolId
      userPoolId
      userPoolClientId
    }
  }
}`;

export const getBusinessSettingsQueryGql = `query GetBusinessSettings ($input: GetBusinessInput!) {
  getBusiness (input: $input) {
    id
    settings {
      subscriptionAllowPause
      subscriptionAllowCancel
      orderAllowCancel
      referralConfiguration {
        enabled
        value {
          amount
          currency {
            symbol
            abbreviation
          }
        }
      }
      rewardConfiguration {
        enabled
        threshold
        value {
          amount
          currency {
            symbol
            abbreviation
          }
        }
      }
    }
  }
}`;

export const getBusinessMetadataQueryGql = `query GetBusinessMetadata ($input: GetBusinessMetadataInput!) {
  getBusinessMetadata (input: $input) {
    id
    createdAt
    updatedAt
    title
    subdomain
    customDomain
    pathSegments
    avatarImage
    coverImage
    toolbarImage
    supportPhoneNumber
    supportEmail
    showPromotePlans
    showGiftCard
    promotePlansText
    supportWidget
    banner
    colorTheme
    metaPixelId
    featurableWidgetId
    headline {
      primary
      secondary
    }
    howItWorks {
      title
      subtitle
      icon
    }
    social {
      facebook
      instagram
      linkedin
      twitter
    }
    faqs {
      id
      createdAt
      updatedAt
      question
      answer
    }
    testimonials {
      id
      createdAt
      updatedAt
      name
      city
      message
    }
    featuredProducts {
      id
      title
      description
      images
      nutrition {
        calorie
        protein
        carbohydrate
        fat
      }
    }
    giftCard {
      minPrice {
        amount
        currency {
          symbol
          abbreviation
        }
      }
      maxPrice {
        amount
        currency {
          symbol
          abbreviation
        }
      }
    }
    marketing {
      provider
      enabled
      integrationScript
      hostedSignUpUrl
    }
  }
}`;

export const getBusinessAboutQueryGql = `query GetBusinessAboutUsTeamMembers ($input: GetBusinessMetadataInput!) {
  getBusinessMetadata (input: $input) {
    id
    aboutUs {
      mission
      origin
    }
    teamMemberProfiles {
      id
      name
      title
      bio
      picture
      position
    }
  }
}`;

export const getBusinessImagesQueryGql = `query GetBusinessImages ($input: GetBusinessMetadataInput!) {
  getBusinessMetadata (input: $input) {
    toolbarImage
    avatarImage
  }
}`;

export const getBusinessFaqsQueryGql = `query GetBusinessFaqs ($input: GetBusinessMetadataInput!) {
  getBusinessMetadata (input: $input) {
    faqs {
      id
      createdAt
      updatedAt
      question
      answer
    }
  }
}`;

export const getBusinessLocationsQueryGql = `query GetBusinessLocations ($input: GetBusinessInput!, $filterInput: FilterLocationsInput) {
  getBusiness (input: $input) {
    locations (input: $filterInput) {
      edges {
        node {
          id
          title
          pathSegment
          status
          description
          awayMessage
          website
        }
      }
    }
  }
}`;

export const getBusinessLocationsPickerQueryGql = `query GetBusinessLocationsPicker ($input: GetBusinessInput!, $filterLocationsInput: FilterLocationsInput, $filterSchedulesInput: FilterSchedulesInput) {
  getBusiness (input: $input) {
    locations (input: $filterLocationsInput) {
      edges {
        node {
          id
          status
          title
          pathSegment
          deliveries {
            edges {
              node {
                id
                postalCodes
              }
            }
          }
          schedules(input: $filterSchedulesInput) {
            edges {
              node {
                id
                status
                type
                orderReadyStart
                orderReadyEnd
                orderCutoff
                pickupAddress {
                  id
                  createdAt
                  updatedAt
                  isDefault
                  status
                  street
                  street2
                  city
                  stateOrProvince
                  country
                  postalCode
                  title
                  lat
                  long
                }
              }
            }
          }
        }
      }
    }
  }
}`;

export const getBusinessProductAllergensQueryGql = `query GetBusinessProductAllergens ($input: GetBusinessMetadataInput!) {
  getBusinessMetadata (input: $input) {
    productAllergens
  }
}`;

export const getBusinessProductCategoriesGql = `query GetBusinessProductCategories ($input: GetBusinessMetadataInput!) {
  getBusinessMetadata (input: $input) {
    id
    productCategories
  }
}`;

export const getBusinessPlansQueryGql = `query GetBusinessPlans ($input: GetBusinessInput!, $filterInput: FilterPlansInput, $pagination: PaginationInput) {
  getBusiness(input: $input) {
    plans(input: $filterInput, pagination: $pagination) {
      total
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          status
          title
          interval
          images
          description
          transportationType
          chargeDay
          allowSelection
          price {
            amount
            currency {
              abbreviation
              symbol
            }
          }
          pickupAddress {
            id
            isDefault
            createdAt
            updatedAt
            status
            lat
            long
            street
            street2
            city
            stateOrProvince
            postalCode
            country
          }
          fulfillment {
            quantity
            day
            product {
              id
              title
              images
              description
            }
          }
        }
      }
    }
  }
}`;

export const getBusinessLegalQueryGql = `query GetBusinessLegal ($input: GetBusinessInput!) {
  getBusiness (input: $input) {
    id
    metadata {
      subdomain
      customDomain
      title
      supportPhoneNumber
      supportEmail
    }
    address {
      id
      createdAt
      updatedAt
      lat
      long
      isDefault
      status
      street
      street2
      city
      postalCode
      country
      stateOrProvince
    }
  }
}`;

export const getBusinessPromotedScheduleQueryGql = `query GetBusinessPromotedSchedule ($input: GetBusinessMetadataInput!) {
  getBusinessMetadata (input: $input) {
    promotedSchedule {
      id
      type
      title
      orderReadyStart
      orderReadyEnd
      orderCutoff
      location {
        pathSegment
      }
      pickupAddress {
        id
        createdAt
        updatedAt
        title
        lat
        long
        isDefault
        status
        street
        street2
        city
        postalCode
        country
        stateOrProvince
      }
    }
  }
}`;
