import { BusinessMetadata, LocationPair } from 'custom-types';
import { createContext, useContext } from 'react';

export interface ICottageAppContext {
  businessId: string;
  businessMetadata: BusinessMetadata;
  locationPairs: LocationPair[];
  consumerId?: string;
}

export const AppContext = createContext<ICottageAppContext | undefined>(undefined);

export function useAppContext(): ICottageAppContext {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within a AppContextProvider');
  }
  return context;
}
